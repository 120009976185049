import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
        Located at 607 W. Pine Street in Fairbury, Illinois
        </p>
        <p>
        3 blocks from FALS
        </p>
        <p>
        Roomy no frills RV Parking. No power/water/sewer/garbage.
        </p>
        <a
          className="App-link"
          href="https://app.fireflyreservations.com/Kiosk/Property/pinestreetcamping/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Make Reservation
        </a>
      </header>
    </div>
  );
}

export default App;
